import type { RouteLocationNormalizedGeneric } from 'vue-router'
import { HOME_PAGE_BY_ROLE, ROUTE } from '~/constants/routes'
import AuthenticationService, {
  AUTHENTICATION_STATUS
} from '~/services/AuthenticationService'
import { hasUserAccessToPage } from '~/services/router'
import { useCurrentUserStore } from '~/stores/currentUser'
import { useCustomizationStore } from '~/stores/customization'
import { useRenamingStore } from '~/stores/renaming'
import { useUserCompanyStore } from '~/stores/userCompany'
import { QUERY_PARAMETERS } from '~/types/queryParameters'

const loadStoresAndCheckPageAccess = async (
  to: RouteLocationNormalizedGeneric
) => {
  const { id, loadCurrentUser } = useCurrentUserStore()
  if (!id) {
    // If query param virtualAccountId exits connect virtual account and use it in place of the current access token
    const virtualAccountId =
      to.query[QUERY_PARAMETERS.VIRTUAl_ACCOUNT_ID]?.toString()

    if (virtualAccountId) {
      await AuthenticationService.connectToVirtualAccount(virtualAccountId)
    }

    await loadCurrentUser()

    const { loadCompanyInfo } = useUserCompanyStore()
    await loadCompanyInfo()

    const { loadRenamings } = useRenamingStore()
    await loadRenamings()

    const { loadCustomizations } = useCustomizationStore()
    await loadCustomizations()
  }

  const user = useCurrentUserStore()

  if (!hasUserAccessToPage(user, to.path)) {
    return await navigateTo(HOME_PAGE_BY_ROLE[user.role])
  }
}

export default defineNuxtRouteMiddleware(async to => {
  const { isLoggedIn, status } = AuthenticationService.isLoggedIn()

  const { resetCurrentUser } = useCurrentUserStore()

  if (isLoggedIn) {
    return await loadStoresAndCheckPageAccess(to)
  } else {
    if (status === AUTHENTICATION_STATUS.EMAIL_NOT_VERIFIED) {
      resetCurrentUser()
      return await navigateTo(ROUTE.LOGIN)
    }

    if (status === AUTHENTICATION_STATUS.TOKEN_EXPIRED) {
      try {
        await AuthenticationService.refreshAccessTokenUsingRefreshToken()
      } catch {
        useCurrentUserStore().resetCurrentUser()

        return await navigateTo({
          path: ROUTE.LOGIN,
          query:
            to.fullPath === ROUTE.ROOT
              ? undefined
              : {
                  [QUERY_PARAMETERS.REDIRECT_URL]: encodeURIComponent(
                    to.fullPath
                  )
                }
        })
      }
      return await loadStoresAndCheckPageAccess(to)
    }
  }
})
